import { useEffect, useState } from "react";
import { datePost, getCookie, getImageForWidgetNews, getVideoLoopUrl } from "../helpers";
import { useClippingForPreview } from "./useClippingForPreview";
import { useRouter } from "next/router";

const useNewsDataModules = (props = {}, clippingsRequired = { desktop: [], mobile: []}) => {  
  const router = useRouter();
  const dynamicType = props?.__extra?.dynamicType ?? "default";
  const [loading, setLoading] = useState(['abTestModule', 'dynamicNewsModule'].includes(dynamicType) && !props?.isPreview);

  const handleStateName = (news, clippingsRequired) => {
    const payWallExclusive = news?.metadata?.payWallAccessControl === "suscribers-only";

    const imageWeb = getImageForWidgetNews({
      clippingsRequired,
      images: news?.images,
      selectedImageId: news?.selectedImageId,
      customClippings: news?.customClippings,
      croppedImages: news?.croppedImages,
      imageOriginUrl: news?.imageOriginUrl
    })  
  
    if(news?.epigraphe && imageWeb) {
      imageWeb.epigraphe = news.epigraphe
    }    

    const urlTarget = news?.urlTarget ? news?.urlTarget : '_self';
    const date = datePost(news?.publishedDate);

    let videoLoop = "";
  
    if(news.videosOpenLoop?.enabled){
      videoLoop = getVideoLoopUrl(news.videosOpenLoop,news.video);
    }

    let urlSelected = news?.url;
    if(news?.urlAlternative?.trim()) {
      urlSelected = news.urlAlternative;
    }

    return {
      ...news,
      url: urlSelected,
      date,
      imageWeb,
      payWallExclusive,
      urlTarget,
      videoLoop
    }
  }

  const [newsData, setNewsData] = useState(handleStateName(props, clippingsRequired));

  const { imagePreview } = useClippingForPreview(
    clippingsRequired,
    props?.images,
    props?.customClippings,
    props?.selectedImageId,
    props?.croppedImages
  );

  const containerName = props?.__extra?.containerName ?? "";

  const formatUrlDynamic = (params, url) => {
    if (router.query.app === "true" || getCookie("app") === "true") {
      return;
    }
    if(newsData?.url?.includes(params)) return;
    return url + params;
  }

  useEffect(() => {
    if (props.isPreview) return;
    let news = JSON.parse(localStorage.getItem('news_reads')) || [];
    const existingNewsIndex = news.findIndex(item => item.id === props.lilaNewsID);
    let newsReaded = false;
    if (existingNewsIndex !== -1) {
      newsReaded = true;
      const oldModificated = new Date(news[existingNewsIndex].modifiedDate);
      const currentModificated = new Date(props?.modificatedAt || new Date(0))
      oldModificated.setMilliseconds(0);
      currentModificated.setMilliseconds(0);
      if (oldModificated < currentModificated){
        newsReaded = false;
        news = news.filter((item) => item.id !== props.lilaNewsID);    
        localStorage.setItem('news_reads', JSON.stringify(news));
      }
    }
    if(dynamicType === "default") return;
    const isRandomDynamicNews = getCookie("randomDynamicNews");
    if (isRandomDynamicNews === "0" && dynamicType === "abTestModule") {
      const newUrl = formatUrlDynamic("?fijo", newsData.url);
      if(newUrl) {        
        setNewsData((prev) => ({
          ...prev,
          url: newUrl 
        }))
      }
      setLoading(false);
      return
    };  
    if(dynamicType === "dynamicNewsModule" || (dynamicType === "abTestModule" && isRandomDynamicNews === "1")) {
      if(newsReaded && props?.relatedLilaNews?.length > 0) {
        let nextNewsIndex = -1;
        for (let i = 0; i < props.relatedLilaNews.length; i++) {
          const relatedNewsIndex = news.findIndex(item => item.id === props.relatedLilaNews[i].id);
          if (relatedNewsIndex === -1) {
            nextNewsIndex = i;
            break;
          }
        }
        if(nextNewsIndex === -1) {
          const newUrl = formatUrlDynamic("?dinamico", newsData.url);
          if(newUrl) {        
            setNewsData((prev) => ({
              ...prev,
              url: newUrl 
            }))
          }
          setLoading(false);
          return;
        }
        setNewsData(() => {
          const news = props.relatedLilaNews[nextNewsIndex];
          // ToDo mostar imagen
          const imageWeb = getImageForWidgetNews({
            clippingsRequired,
            images: news?.images,
          });
          const newUrl = formatUrlDynamic("?dinamico_rotacion", news.url);

          return {
            ...news,
            url: newUrl,
            imageWeb
          }
        });
      } else {
        const newUrl = formatUrlDynamic("?dinamico", newsData.url);
        if(newUrl) {        
          setNewsData((prev) => ({
            ...prev,
            url: newUrl 
          }))
        }
      }
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    if (!props.isPreview) return;
    setNewsData(handleStateName(props, clippingsRequired));
  }, [props]);

  return {
    ...newsData,
    loading,
    asPath: router.asPath,
    containerName,
    imagePreview,
  };
}

export default useNewsDataModules;